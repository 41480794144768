export default class {
    constructor() {
        this.$btn = $('.u-breadCrumb_link');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$btn
            .on('click',(e)=>{
                if ($(e.currentTarget).attr('href') == 'none') {
                    return false;
                }
            })
    }
}
