export default class {
    constructor() {
        this.$replaceImg = $('.js-replaceImg');
        this.$sizingImg = $('.js-sizeImg');
    }
    init(){
        this.resizeEvent();
    }
    resizeEvent(){
        this.switchWindow();

        $(window)
            .on('resize',()=>{
                this.winW = $(window).width();
                this.switchWindow();
            })
    }
    switchWindow(){
        if(app.point.winW <= app.point.breakPoint && this.$flag != 'sp') {
            this.$flag = 'sp';

            this.replaceSP();
            this.sizingImg();
        } else if(app.point.winW > app.point.breakPoint && this.$flag != 'pc') {
            this.$flag = 'pc';

            this.replacePC();
            this.sizingImg();
        }
    }
    replacePC(){
        this.$replaceImg.each((i, el)=>{
            $(el).attr('src', $(el).attr('src').replace('_sp', ''))
        });
    }
    replaceSP(){
        this.$replaceImg.each((i, el)=>{
            $(el).attr('src', $(el).attr('src').replace(/(\.gif|\.jpg|\.png)/g, '_sp$1'))
        });
    }
    sizingImg(){
        if (!this.$sizingImg.length) { return }

        this.$sizingImg.each((i, el)=>{
            if (this.$flag == 'pc') {
                this.w = $(el).data('pc');
            } else if (this.$flag == 'sp') {
                this.w = $(el).data('sp');
            }

            $(el).css({
                'width': this.w
            })
        })
    }
}
