export default class {
    constructor() {
        this.$el = $('.js-frame');
        this.$el_child = this.$el.find('.js-frameItem');

        this.adjustPoint = 100;
    }
    init(){
        if (this.$el.length == 0) { return }

        this.setEvent();
    }
    setEvent(){
        this.$el_child.css({ 'opacity': 0 })

        $(window).on('load',()=>{
            this.showConts();
        });
    }
    showConts(){
        TweenMax.to(this.$el_child, 1.2, {
            ease: Power2.easeOut,
            'opacity': 1,
            delay: 1.2
        });
    }
}
