export default class {
    constructor() {
        this.$el = $('.u-pageTitle');
        this.$main = $('.u-mainArea');

        this.adjustPoint = 100;
    }
    init(){
        if (this.$el.length == 0) { return }

        this.setEvent();
    }
    setEvent(){
        $(window)
            .on('scroll',()=>{
                this.checkState('scroll');
            });

        $(window)
            .on('load resize scroll',()=>{
                this.checkState('all');
            });
    }
    checkState(state){
        if (state == 'scroll') {
            this.scrollH = $(window).scrollTop();
        } else if (state == 'all') {
            
            this.scrollH = $(window).scrollTop();
            this.top = this.$main.offset().top;
            this.left = this.$main.offset().left - $('.u-titleArea').outerWidth();

            this.winH = $(window).outerHeight();
            this.point = $('body').outerHeight() - (this.winH + $('.u-footer').outerHeight()) - this.adjustPoint;
        }


        if (this.point <= 0) {
            this.moveTitle('short');
        } else {
            this.moveTitle('long');
        }
    }
    moveTitle(state){

        if (state == 'long') {
            if (this.scrollH > this.point) {
                this.$el.css({
                    'position': 'absolute',
                    'top': this.point,
                    'left': 0
                });
            } else {
                this.$el.css({
                    'position': 'fixed',
                    'top': this.top,
                    'left': this.left
                });
            }
        } else if (state == 'short') {
            this.$el.css({
                'position': 'absolute',
                'top': 0,
                'left': 0
            });
        }
    }
}
