import Common from '../_project-modules/page';

class Page extends Common{
    constructor() {
        super();
    }
    init(){
        super.init();
    }
}

app.page = Page;
app.start();
