export default class {
    constructor() {
        this.$spBtn = $('.spMenu_btn');
        this.$spModal = $('.spMenuModal');
        this.$langBtn = $('.js-lang');
        this.modalOption = {
            fadeDuration: 250
        }

        this.opend = false;
        this.opendLang = false;
        this.dev = false;
    }
    init(){
        this.setModal();
        this.setEvent();

        if (this.dev) {
            this._showMenu();
        }
    }
    setModal(){
        this.$cloneConts = $('.u-header_list').clone();
        this.$cloneConts.prependTo('.spMenuModal .spMenuModal_main');
    }
    setEvent(){
        $(window)
            .on('load resize',()=>{
                this.winW = $(window).width();

                this.switchWindow();
            })

        this.$spBtn
            .on('click',(e)=>{
                this.$target = $(e.currentTarget);

                if (!this.$target.hasClass('-opend')) {
                    this._showMenu();
                } else {
                    this._closeMenu();
                }

                return false;
            });

        this.$spModal
            .on($.modal.BEFORE_CLOSE, (event, modal)=>{
                this.$spBtn.removeClass('-opend');
                this.opend = false;
            });

        $(document)
            .on('click','.u-header_link.-teaser',()=>{
                console.log('coming soon...');
                return false;
            });

        this.$langBtn
            .on('click', ()=>{

                if (this.opend) {
                    this._closeMenu();
                }

                if (this.opendLang) {
                    this._closeLang();
                } else {
                    this._openLang();
                }

                return false;
            });
    }
    switchWindow(){
       if(this.winW <= app.point.breakPoint && this.$flag != 'sp') {
           this.$flag = 'sp';
       } else if(this.winW > app.point.breakPoint && this.$flag != 'pc') {
           this.$flag = 'pc';

           if (this.opend) {
               this._closeMenu();
           }
       }
    }
    _showMenu(){
        this.$spBtn.addClass('-opend');
        this.$spModal.css({
            'top': $('.u-header').outerHeight()
        })

        this.$spModal.modal(this.modalOption);
        this.opend = true;

        if (this.opendLang) {
            this._closeLang();
        }
    }
    _closeMenu(){
        $('.close-modal').trigger('click');
    }
    _openLang(){
        $('.u-lang').addClass('isOpen');
        $('.u-lang_panel').stop().slideDown(300);
        this.opendLang = true;
    }
    _closeLang(){
        $('.u-lang').removeClass('isOpen');
        $('.u-lang_panel').stop().slideUp(300);
        this.opendLang = false;
    }
}
