export default class {
    constructor() {
        this.$el = $('.u-onlineshopBanner');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        $(window).on('scroll resize',()=>{

            this.scrollH = $(window).scrollTop();
            this.point = {
                top: 100,
                bottom: $('body').outerHeight() - $(window).outerHeight() - $('.u-footer').outerHeight()
            }

            this.switchWindow();
        });
    }
    switchWindow(){
       if(this.scrollH <= this.point.top && this.$flag != 'hide') {
           this.$flag = 'hide';
           this._hide();
       } else if(this.scrollH > this.point.bottom && this.$flag != 'hide') {
           this.$flag = 'hide';
           this._hide();
       } else if(this.scrollH > this.point.top && this.scrollH <= this.point.bottom && this.$flag != 'show') {
           this.$flag = 'show';
           this._show();
       }
    }
    _show(){
        this.$el.stop().fadeIn();
    }
    _hide(){
        this.$el.stop().fadeOut();
    }
}
