import ImgResize from './_modules/imgResize';
import Scroll from './_modules/scroll';
import Nav from './_modules/nav';
import PageTitle from './_modules/pageTitle';
import Breadcrumb from './_modules/breadcrumb';
import Loader from './_modules/loader';
import PageVisual from './_modules/pageVisual';
import OnlineshopBanner from './_modules/onlineshopBanner';

export default class {
    constructor() {
    }
    init(){
        this.imgResize = new ImgResize();
        this.imgResize.init();

        this.scroll = new Scroll();
        this.scroll.init();

        this.nav = new Nav();
        this.nav.init();

        this.pageTitle = new PageTitle();
        this.pageTitle.init();

        this.breadcrumb = new Breadcrumb();
        this.breadcrumb.init();

        this.loader = new Loader();
        this.loader.init();

        this.pageVisual = new PageVisual();
        this.pageVisual.init();

        this.onlineshopBanner = new OnlineshopBanner();
        this.onlineshopBanner.init();
    }
}
