export default class {
    constructor() {
        this.$el = $('.u-loader');
        this.speed = 300;
    }
    init(){
        $(window)
            .on('load',()=>{
                setTimeout(()=>{
                    this.$el.fadeOut();
                },this.speed)
            });
    }
}
